import { Stack, Typography, SxProps } from '@mui/material';
import React from 'react';

type Props = {
  title: string;
  description: string;
  sx?: SxProps;
  component?: React.ElementType;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export default function IntroHead({
  title,
  description,
  sx,
  component,
  variant,
}: Props) {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={sx}
    >
      <Typography
        component={component || 'h2'}
        variant={variant || 'h2'}
        textAlign="center"
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight="light"
        gutterBottom
        textAlign="center"
        color="text.secondary"
        sx={{
          fontSize: '1rem',
        }}
      >
        {description}
      </Typography>
    </Stack>
  );
}
